import "./index.css";
import Modal from "components/ModalPopUp";
import { useState } from "react";
import SignUpModal from "components/SignUpModal";
import { Link, useMatch } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import sun from "assets/drawables/sun.svg";
import moon from "assets/drawables/moon.svg";

import "./style.scss";
import cn from "classnames";
import { useProfileQuery } from "pages/Authentication/queries";

export default function TopNavbar() {
  const { data: user } = useProfileQuery();

  const [selected, setSelected] = useState("GB");
  const [openModal, setOpenModal] = useState(false);

  const [toggle, setToggle] = useState(false);
  const isBusinessPage = useMatch("/business");

  return (
    <>
      <header className="App-header">
        <div className="logo">
          <Link to="/">
            <h2>LOGO</h2>
          </Link>
        </div>
        <nav>
          <p className="user-types">
            <Link to="/" className={cn('link', { active: !isBusinessPage })}>
              Personal
            </Link>
            <span className="dot">|</span>
            <Link to="/business" className={cn('link', { active: isBusinessPage })}>
              Business
            </Link>
          </p>
          <ul className="navList">
            <li>
              <Link to="/how-it-works">How it works</Link>
            </li>
            <li>
              <Link to="/market">Markets</Link>
            </li>
            {/* <li>Forex Trading</li> */}
          </ul>
          <div className="selectLang">
            <ReactFlagsSelect
              selected={selected}
              countries={["GB", "FR", "DE", "IT", "CN", "ES", "SA"]}
              customLabels={{
                GB: "English",
                FR: "French",
                DE: "German",
                IT: "Italian",
                CN: "Chinese",
                ES: "Spanish",
                SA: "Arabic",
              }}
              onSelect={(code) => setSelected(code)}
              placeholder="Pick Language"
            />
          </div>
          <div className="btnDiv">
            {user ? (
              <Link to="/dashboard">
                <button className="signup">
                  Dashboard
                </button>
              </Link>
            ) : (
              <>
                <Link to="/login">
                  <button className="login">
                    Log In
                  </button>
                </Link>
                <button onClick={() => setOpenModal(true)} className="signup">
                  Sign Up
                </button>
              </>
            )}
          </div>
          <div className="toggleSide">
            <img src={sun} alt="sun" />
            <div className="togDiv">
              <label className="switch">
                <input
                  checked={toggle}
                  onChange={() => setToggle(!toggle)}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>
            <img src={moon} alt="moon" />
          </div>
        </nav>
      </header>
      <Modal closeModal={() => setOpenModal(false)} openModal={openModal}>
        <SignUpModal />
      </Modal>
    </>
  );
};

import { useReducer } from "react";
import "./style.scss";
import SendMoneyHeader from "../../widget/SendMoneyHeader";
import blueindicate from "assets/drawables/blueIndicator.svg";
import indicate from "assets/drawables/indicate.svg";
import union from "assets/drawables/Union.svg";
import ech from "assets/drawables/ech.svg";
import timeImg from "assets/drawables/timeImg.svg";
import SubmitBtn from "components/SubmitBtn/SubmitBtn";
import { Link } from "react-router-dom";
import ConvertCurrencyDropDown from "../../../../../components/InputFields/convertCurrencyDropDown";
import { useSendMoneyContext } from "../../SendMoneyProvider";
import { useCurrenciesQuery } from "default/queries";

type ValuesState = Record<'sendingAmount' | 'receivingAmount' | 'fromCurrency' | 'toCurrency', string>;
type ValuesAction = { type: keyof ValuesState, value: string };

function valuesReducer(state: ValuesState, action: ValuesAction): ValuesState {
  switch(action.type) {
    /** @todo Update once inter-currency transfer is possible */
    case 'fromCurrency':
    case 'toCurrency':
      return { ...state, fromCurrency: action.value, toCurrency: action.value };
    /** @todo Use conversion to determine amounts when inter-currency transfer is possible */
    case 'sendingAmount':
    case 'receivingAmount':
      const amount = action.value;
      return { ...state, sendingAmount: amount, receivingAmount: amount };
  }
}

export const AmountStep = () => {
  const { data: currencies } = useCurrenciesQuery();

  const { gotoNextStep, transfer, updateTransfer } = useSendMoneyContext();
  const [values, dispatch] = useReducer(valuesReducer, {
    sendingAmount: String(transfer.sendingAmount || 1),
    receivingAmount: String(transfer.receivingAmount || 1),
    fromCurrency: transfer.fromCurrency || currencies[0].code,
    toCurrency: transfer.toCurrency || currencies[0].code,
  });

  const onSubmit = () => {
    updateTransfer({
      fromCurrency: values.fromCurrency,
      toCurrency: values.toCurrency,
      sendingAmount: Number(Number(values.sendingAmount).toFixed(2)),
      receivingAmount: Number(Number(values.receivingAmount).toFixed(2)),
    });
    gotoNextStep();
  };

  /** @todo Validate using schema and display error to user */
  const isSubmitEnabled = values.sendingAmount && Number(values.sendingAmount) >= 10;

  return (
    <div className="pd_send_money">
      <SendMoneyHeader active="amount" />
      <div className="main_aspect">
        <p className="first_text_send bold">How much do you want to send?</p>
        <div className="cover_tender">
          <div className="changeBox">
            <div className="top hard">
              <div className="left">
                <p className="changeText">Tender</p>
                <input
                  type="number"
                  // inputMode="decimal"
                  className="number"
                  value={values.sendingAmount}
                  onChange={(e) => dispatch({ type: 'sendingAmount', value: e.target.value })}
                  // min={0}
                />
              </div>
              <div className="right">
                <ConvertCurrencyDropDown
                  currencies={currencies}
                  value={values.fromCurrency}
                  onChange={(value: string) => dispatch({ type: 'fromCurrency', value })}
                />
              </div>
            </div>
            <div className="top">
              <div className="left">
                <p className="changeText">Buy</p>
                <input
                  type="number"
                  // inputMode="decimal"
                  className="number"
                  value={values.receivingAmount}
                  onChange={(e) => dispatch({ type: 'receivingAmount', value: e.target.value })}
                  // min={0}
                />
              </div>
              <div className="right">
                <ConvertCurrencyDropDown
                  currencies={currencies}
                  value={values.toCurrency}
                  onChange={(value: string) => dispatch({ type: 'toCurrency', value })}
                />
              </div>
            </div>
          </div>
          <p className="save">
            You could save 10.24 USD using our platform{" "}
            <img src={blueindicate} alt="indicate" />
          </p>
          <div className="eachdol">
            <img src={indicate} alt="indicaate" />
            <p>
              <span className="bold">$20</span>
              Processing Fee
            </p>
          </div>
          <div className="eachdol">
            <img src={ech} alt="indicaate" />
            <p>
              <span className="bold">$20</span>
              <img src={union} alt="" />
              Exchange Rate
            </p>
          </div>
          <div className="eachdol">
            <img src={timeImg} alt="indicaate" />
            <p>
              They will receive in
              <span className="bold blue">20 mins</span>
            </p>
          </div>

          <SubmitBtn
            disabled={!isSubmitEnabled}
            onClick={onSubmit}
            className="green"
            btnText='Proceed'
          />
          <div className="last_div">
            <img src={indicate} alt="indicate" />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod{" "}
            </p>
          </div>
          <Link className="cancel" to='/dashboard'>
            Cancel
          </Link>
        </div>
      </div>
    </div>
  );
};


export const currencies = [
	{
		code: 'AUD',
		name: 'Australian Dollar',
		symbol: '$',
		flag: '',
		rating: 'AAA',
		tier: 47,
		active: true
	},
	{
		code: 'BGN',
		name: 'Bulgarian lev',
		symbol: 'лв',
		flag: '',
		rating: 'BBB',
		tier: 37,
		active: true
	},
	{
		code: 'BRL',
		name: 'Brazilian real',
		symbol: 'R$',
		flag: '',
		rating: 'AA',
		tier: 44,
		active: true
	},
	{
		code: 'CAD',
		name: 'Canadian Dollar',
		symbol: '$',
		flag: '',
		rating: 'AAA',
		tier: 47,
		active: true
	},
	{
		code: 'CHF',
		name: 'Swiss Franc',
		symbol: 'CHF',
		flag: '',
		rating: 'AAA+',
		tier: 48,
		active: true
	},
	{
		code: 'CNY',
		name: 'Yuan or chinese renminbi',
		symbol: '¥',
		flag: '',
		rating: 'AA+',
		tier: 45,
		active: true
	},
	{
		code: 'CZK',
		name: 'Czech koruna',
		symbol: 'Kč',
		flag: '',
		rating: 'AA',
		tier: 44,
		active: true
	},
	{
		code: 'DKK',
		name: 'Danish krone',
		symbol: 'kr',
		flag: '',
		rating: 'AA+',
		tier: 45,
		active: true
	},
	{
		code: 'EUR',
		name: 'Euro',
		symbol: '€',
		flag: '',
		rating: 'AAA',
		tier: 47,
		active: true
	},
	{
		code: 'GBP',
		name: 'British Pound',
		symbol: '£',
		flag: '',
		rating: 'AAA',
		tier: 47,
		active: true
	},
	{
		code: 'HUF',
		name: 'Hungarian forint',
		symbol: 'Ft',
		flag: '',
		rating: 'BBB',
		tier: 37,
		active: true
	},
	{
		code: 'INR',
		name: 'Indian Rupee',
		symbol: '₹',
		flag: '',
		rating: 'A',
		tier: 41,
		active: true
	},
	{
		code: 'ISK',
		name: 'Icelandic Krona',
		symbol: 'kr',
		flag: '',
		rating: 'AA+',
		tier: 45,
		active: true
	},
	{
		code: 'JMD',
		name: 'Jamaican dollar',
		symbol: 'J$',
		flag: '',
		rating: 'B',
		tier: 31,
		active: true
	},
	{
		code: 'JPY',
		name: 'Japanese yen',
		symbol: '¥',
		flag: '',
		rating: 'AA+',
		tier: 45,
		active: true
	},
	{
		code: 'KRW',
		name: 'South Korean won',
		symbol: '₩',
		flag: '',
		rating: 'AA',
		tier: 44,
		active: true
	},
	{
		code: 'MXN',
		name: 'Mexico Peso',
		symbol: '$',
		flag: '',
		rating: 'AA-',
		tier: 43,
		active: true
	},
	{
		code: 'NGN',
		name: 'Nigerian Naira',
		symbol: '₦',
		flag: '',
		rating: 'B',
		tier: 31,
		active: true
	},
	{
		code: 'NOK',
		name: 'Norwegian krone',
		symbol: 'kr',
		flag: '',
		rating: 'AA+',
		tier: 45,
		active: true
	},
	{
		code: 'NZD',
		name: 'New Zealand Dollar',
		symbol: '$',
		flag: '',
		rating: 'AAA',
		tier: 47,
		active: true
	},
	{
		code: 'PLN',
		name: 'Polish złoty',
		symbol: 'zł',
		flag: '',
		rating: 'AA',
		tier: 44,
		active: true
	},
	{
		code: 'RON',
		name: 'Romanian leu',
		symbol: 'lei',
		flag: '',
		rating: 'A',
		tier: 41,
		active: true
	},
	{
		code: 'SEK',
		name: 'Swedish krona',
		symbol: 'kr',
		flag: '',
		rating: 'AA+',
		tier: 45,
		active: true
	},
	{
		code: 'TND',
		name: 'Tunisian Dinar',
		symbol: '',
		flag: '',
		rating: 'B+',
		tier: 32,
		active: true
	},
	{
		code: 'USD',
		name: 'US Dollar',
		symbol: '$',
		flag: '',
		rating: 'AAA',
		tier: 47,
		active: true
	},
	{
		code: 'ZAR',
		name: 'South African Rand',
		symbol: 'R',
		flag: '',
		rating: 'BBB',
		tier: 37,
		active: true
	}
];
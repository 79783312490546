import snakeCase from "lodash/snakeCase";
import isObject from "lodash/isObject";
import transform from "lodash/transform";
import camelCase from "lodash/camelCase";
import { Currency } from "./api/apiTypes";
import round from "lodash/round";

export function convertToSnakeCase(object: object) {
	return transform(object, (result: Record<string, any>, value: any, key: string) => {
		const snakeCaseKey = snakeCase(key);
		result[snakeCaseKey] = isObject(value) ? convertToSnakeCase(value) : value;
	});
}

/**
 * Converts FormData keys to snake case, returning a new instance
 * Assumes single dimension
 */
export function convertFormDataToSnakeCase(data: FormData) {
	const newData = new FormData();
	for (const [key, value] of data.entries()) {
		const newKey = snakeCase(key);
		newData.append(newKey, value);
	}
	return newData;
}

export function convertToCamelCase(object: object) {
	return transform(object, (result: Record<string, any>, value, key) => {
		const camelCaseKey = camelCase(key);
		result[camelCaseKey] = isObject(value) ? convertToCamelCase(value) : value;
	});
}

/** Split full name into first, middle and last names */
export function splitFullName(fullName: string) {
	const nameParts = String(fullName).trim().split(/\s+/);

	if (nameParts.length === 1) {
		return [nameParts[0], '', ''];
	} else if (nameParts.length === 2) {
		return [nameParts[0], '', nameParts[1]];
	} else {
		const firstName = nameParts[0];
		const middleName = nameParts.slice(1, -1).join(' ');
		const lastName = nameParts[nameParts.length - 1];
		return [firstName, middleName, lastName];
	}
}

export function sleep(ms: number) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export function formatAmount(amount: number, currency: string) {
	const formatter = new Intl.NumberFormat('en', { style: 'currency', currency });
	return formatter.format(amount);
}

export function mapCurrencies(currencies: Currency[]) {
	const map: Record<string, Currency> = {};
	for (const item of currencies) {
		map[item.code] = item;
	}
	return map;
}

export function formatNumberWithSuffix(number: number) {
	const suffixes = ['', 'K', 'M', 'B', 'T'];
	let suffixIndex = 0;

	while (number >= 1000 && suffixIndex < suffixes.length - 1) {
		number /= 1000;
		suffixIndex++;
	}

	// Adjust the number of decimal places based on the suffix
	const precision = number % 1 === 0 ? 0 : 2;

	return `${round(number, precision)} ${suffixes[suffixIndex]}`;
}

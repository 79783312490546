import { MarketType, numberFormatter, percentFormatter, trendDownData, trendUpData } from "./utils";
import greenArr from "../../assets/drawables/greenArrow.svg";
import redArr from "../../assets/drawables/redArrow.svg";
import { ReactComponent as Star } from "../../assets/drawables/star.svg";
import { ReactComponent as StarSolid } from "../../assets/drawables/star_solid.svg";
import { Link, useNavigate } from "react-router-dom";
import { MouseEventHandler } from "react";
import { Box } from "@mui/material";
import cn from "classnames";
import Trend from "react-trend";
import "./style.scss";
import { Security } from "./queries";

type AssetRowProps = {
	marketType: MarketType,
	asset: Security,
	inWatchlist?: boolean,
	index: number,
	onStarClick?: () => void,
}
export function AssetRow({
	asset,
	marketType,
	inWatchlist = false,
	index,
	onStarClick = () => { },
}: AssetRowProps) {
	const navigate = useNavigate();

	let assetCap = marketType === 'primary' ? asset.marketCap : asset.volume;
	if (assetCap < 0) assetCap = 0;

	const yieldPa = marketType === 'primary' ? asset.yieldPa : asset.discount;

	const changeClass = asset.change24H < 0 ? 'red' : 'green';

	const actionOnClick: MouseEventHandler<HTMLButtonElement> = (ev) => {
		ev.stopPropagation();
	}

	const actions = marketType === 'primary' ? (
		<button className="invest" onClick={actionOnClick}>Invest</button>
	) : (
		<Box display="flex" gap={2}>
			<button className="invest" onClick={actionOnClick}>Borrow</button>
			<button className="invest" onClick={actionOnClick}>Buy</button>
		</Box>
	);

	return (
		<tr className="asset-row" onClick={() => navigate(`/market/${asset.currency}`)}>
			<td>{inWatchlist ? <StarSolid onClick={onStarClick} /> : <Star onClick={onStarClick} />}</td>
			<td className="serial">{index}</td>
			<td>
				<Link to={`/market/${asset.currency}`}>
					<div className="country">
						<img src={asset.currencyRecord.flag} alt="flag" />
						<div className="textSide">
							<p className="top">{asset.currency}</p>
						</div>
					</div>
				</Link>
			</td>
			<td className="serial">{numberFormatter.format(assetCap)}</td>
			<td className="serial center">{percentFormatter.format(yieldPa)}</td>
			<td>
				<Box display="flex" gap={0.5}>
					<img src={asset.change24H < 0 ? redArr : greenArr} alt={changeClass} />
					<p className={cn('serial', changeClass)}>{percentFormatter.format(Math.abs(asset.change24H))}</p>
				</Box>
			</td>
			<td className="serial center">{percentFormatter.format(asset.defaultRate)}</td>
			<td className="center"><p>{asset.currencyRecord.rating}</p></td>
			<td className="center"><p className="green">Active</p></td>
			<td className="center">
				<div className="trend">
					<Trend
						data={asset.change24H < 0 ? trendDownData : trendUpData}
						autoDraw
						autoDrawDuration={200}
						autoDrawEasing="ease-in"
						gradient={[asset.change24H < 0 ? "#F34018" : "#34E36F"]}
					/>
				</div>
			</td>
			<td className="center">{actions}</td>
		</tr>
	);
}
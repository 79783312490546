import ListConversion from "components/ListConversion";
import TopNavbar from "components/navbar/top_navbar";
import PageDescription from "components/PageDescription";

import { useMemo, useState } from "react";

import "./style.scss";
import { Pagination, Typography } from "@mui/material";
import SearchComponent from "components/SearchComponent";
import cn from "classnames";
import _ from "lodash";
import { filters, MarketType, marketTypes, numberFormatter, transformers } from "./utils";
import { AssetRow } from "./asset-row";
import { useSecuritiesQuery } from "./queries";


export default function MarketPage() {
  const { data: assets } = useSecuritiesQuery();

  const [marketType, setMarketType] = useState<MarketType>('primary');
  const [watchlist, setWatchlist] = useState<string[]>([]);

  const totalMarketCap = assets.reduce((acc, asset) => acc + asset.marketCap, 0);
  const totalVolume = assets.reduce((acc, asset) => acc + asset.volume, 0);

  const marketTotal = marketType === 'primary'
    ? `Total Market Cap: $${numberFormatter.format(totalMarketCap)}`
    : `Total Volume: $${numberFormatter.format(totalVolume)}`;

  const [activeFilter, setActiveFilter] = useState<keyof typeof transformers | null>(null);

  const transformedAssets = useMemo(() => {
    if (!activeFilter) return assets;
    return transformers[activeFilter].transformer(assets, watchlist);
  }, [assets, activeFilter, watchlist]);

  const onFilterBtnClick = (type: typeof filters[number]) => {
    setActiveFilter((prev) => {
      if (!prev || !prev.startsWith(type)) return type;
      return transformers[prev].next;
    });
  };

  return (
    <div className="pd_market_page">
      <TopNavbar />
      <ListConversion />
      <div id="market_page_page_description_wrapper">
        <PageDescription
          title="Markets"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt u"
          date="Data as at 10th January 2021, 10:00AM"
        />
        <Typography variant="body1" color="primary">
          {marketTotal}
        </Typography>
      </div>
      <div className="cover_all">
        <div className="filterDiv">
          <div className="btn_cover">
            {Object.keys(marketTypes).map((key) => {
              return (
                <button
                  className={cn("filter_btn", { active: key === marketType })}
                  onClick={() => setMarketType(key as MarketType)}
                  key={key}
                >
                  {marketTypes[key as MarketType].name}
                </button>
              );
            })}
          </div>
          <SearchComponent />
        </div>

        <div className="btn_cover">
          {filters.map((key) => (
            <button
              className={cn("filter_btn", { active: activeFilter?.startsWith(key) })}
              onClick={() => onFilterBtnClick(key)}
              key={key}
            >
              {activeFilter?.startsWith(key) ? transformers[activeFilter].name : _.capitalize(key)}
            </button>
          ))}
        </div>

        <div className="marketTable">
          <div className="overflow">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>#</th>
                  <th>Asset</th>
                  <th>{marketType === 'primary' ? 'Market Cap' : 'Volume'}</th>
                  <th className="center">
                    {marketType === 'primary' ? 'Yield (pa)' : 'Discount'}
                  </th>
                  <th>Change</th>
                  <th className="center">Default Rate</th>
                  <th className="center">Ratings</th>
                  <th className="center">Status</th>
                  <th className="center">Trend (Last 30 days)</th>
                  <th className="center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {transformedAssets.map((asset, index) => (
                  <AssetRow
                    key={asset.currency}
                    asset={asset}
                    marketType={marketType}
                    index={index + 1}
                    inWatchlist={watchlist.includes(asset.currency)}
                    onStarClick={() => {
                      if (watchlist.includes(asset.currency)) {
                        setWatchlist(watchlist.filter((item) => item !== asset.currency));
                      } else {
                        setWatchlist([...watchlist, asset.currency]);
                      }
                    }}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-wrap">
            <Pagination
              color="primary"
              onChange={() => { }}
              count={5}
              shape="rounded"
            // components={{ previous: "Prev", next: "Next" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

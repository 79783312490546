import { isPlainObject } from "lodash";
import { convertToCamelCase, convertToSnakeCase, sleep } from "../utils";
import { APIResponse, Auth, Country, Currency, ID, InboundPayment, MarketSecurity, ReferralSummary, SecondarySecurity, Transfer, TransferRecipient, User, Wallet, WalletTransactionMinimal, WalletWithTransactions } from "./apiTypes";
import { AppInfo, CreateTransferDto, CreateTransferRecipientDto, GetWalletTransactionQuery, RequestPhoneVerificationData, UpdateProfileDto, UserLoginData, UserLoginDto, UserSignupData, UserSignupDto, VerifyPhoneNumberDto } from "./requestTypes";

export const BASE_URL = 'https://api.ice.realjay.dev';


let auth: Auth | undefined;

function getAuth() {
	if (auth) return auth;
	try {
		auth = JSON.parse(localStorage.getItem('auth') ?? '') as Auth;
		return auth;
	} catch (e) {}
}

function setAuth(apiAuth: Auth) {
	auth = apiAuth;
	try {
		localStorage.setItem('auth', JSON.stringify(auth));
	} catch(e) {}
}

export function clearAuth() {
	auth = undefined;
	try {
		localStorage.removeItem('auth');
	} catch(e) {}
}

export function hasAuth() {
	return !!getAuth();
}

export class APIResponseError extends Error {
	statusCode: number;

	constructor(message: string, readonly response: Response) {
		super(message);
		this.statusCode = response.status;

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, APIResponseError);
		}
	}
}

export type HttpMethods = 'GET' | 'POST' | 'PUT' | 'DELETE';

async function request<DataType = any, MetaType = any>(url: string | URL, method: HttpMethods = 'GET', body?: object) {
	const fullUrl = typeof url === 'string' ? new URL(url, BASE_URL) : url;

	const headers = new Headers({ accepts: 'application/json' });
	const bodyIsPlainObject = isPlainObject(body);
	if (bodyIsPlainObject) {
		headers.set('content-type', 'application/json');
	}
	if (!auth) auth = getAuth();
	if (auth) headers.set('authorization', `Bearer ${auth.token}`);

	const request: RequestInit = {
		method,
		mode: 'cors',
		headers,
		redirect: 'follow',
	};
	if (body) {
		if (bodyIsPlainObject) {
			const snakeCasedBody = convertToSnakeCase(body);
			request.body = JSON.stringify(snakeCasedBody);
		} else {
			request.body = body as FormData;
		}
	}

	if (process.env.NODE_ENV === 'development') {
		// Delay response in development
		// return Promise.reject(new Error('Testing error'));
		await sleep(500);
	}

	const response = await fetch(fullUrl, request);
	const responseBody = convertToCamelCase(await response.json()) as APIResponse<DataType, MetaType>;
	if (response.ok) {
		return responseBody;
	}

	const apiError = new APIResponseError(responseBody.message ?? '', response);
	console.error(apiError);
	throw apiError;
}

export async function signupUser(body: UserSignupDto) {
	const response = await request<UserSignupData>('/signup', 'POST', body);
	setAuth(response.data.auth);
	return response;
}

export async function getProfile() {
	// await sleep(60 * 60 * 1000);
	const response = await request<User>('/profile');
	return response.data;
}

export async function getAppInfo() {
	const response = await request<AppInfo>('/info');
	return response.data;
}

export async function getCurrencies() {
	const response = await request<Currency[]>('/info/currencies');
	return response.data;
}

export async function getCountries() {
	const response = await request<Country[]>('/info/countries');
	return response.data;
}

export async function loginUser(body: UserLoginDto) {
	const response = await request<UserLoginData>('/login', 'POST', body);
	setAuth(response.data.auth);
	return response;
}

export function requestPhoneVerification(phone: string) {
	return request<RequestPhoneVerificationData>('/phone/request-verification', 'POST', { phone });
}

export function verifyPhoneNumber(body: VerifyPhoneNumberDto) {
	return request('/phone/verify', 'POST', body);
}

export function updateProfile(body: UpdateProfileDto) {
	return request('/profile', 'PUT', body);
}

export function uploadFile(body: FormData) {
	return request('/file/upload', 'POST', body);
}

export async function createTransferRecipient(body: CreateTransferRecipientDto) {
	const response = await request<TransferRecipient>('/transfer-recipient', 'POST', body);
	return response.data;
}

export async function getTransferRecipients() {
	const response = await request<TransferRecipient[]>('/transfer-recipient');
	return response.data;
}

export async function createTransfer(body: CreateTransferDto) {
	const response = await request<Transfer>('/transfer', 'POST', body);
	return response.data;
}

export function payTransferWithWallet(id: ID) {
	return request(`/transfer/${id}/pay/wallet`, 'POST');
}

export function payTransferWithCard(id: ID) {
	return request<InboundPayment>(`/transfer/${id}/pay/card`, 'POST');
}

export async function getWalletTransactions(query: GetWalletTransactionQuery) {
	const params = new URLSearchParams(convertToSnakeCase(query) as Record<string, string>);
	const response = await request<WalletTransactionMinimal[]>(`/wallet/transaction?${params.toString()}`);
	return response.data;
}

/** @todo Create correct response type for request */
export async function getWalletTransaction(id: ID) {
	const response = await request<WalletTransactionMinimal>(`/wallet/transaction/${id}`);
	return response.data;
}

export function createWallet(currency: string) {
	return request<Wallet>('/wallet', 'POST', { currency });
}

export async function getWallet(id: ID) {
	const response = await request<WalletWithTransactions>(`/wallet/${id}`);
	return response.data;
}

export function topupWallet(id: ID, amount: number) {
	return request<InboundPayment>(`/wallet/${id}/topup`, 'POST', { amount });
}

export async function getReferralData() {
	const response = await request<ReferralSummary>('/referral/summary');
	return response.data;
}

export function validateReferralCode(code: string) {
	return request(`/referral/validate/${code}`);
}

export async function getMarketInfo() {
	const response = await request<MarketSecurity[]>('/market');
	return response.data;
}

export async function getSecondaryMarketInfo() {
	const response = await request<SecondarySecurity[]>('/market/secondary');
	return response.data;
}

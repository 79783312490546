import React from "react";
import "./App.css";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ScrollToTop from "components/ScrollToTop";
import { Router } from "./Router";
import { ThemeProvider, createTheme } from "@mui/material";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
    },
  }
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#173963',
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <div className="app_wrapper">
          <ScrollToTop />
          <Router />
        </div>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { APIResponseError, clearAuth, getProfile, hasAuth } from "../../lib/api";
import { splitFullName } from "lib/utils";

export const UserQueryKeys = {
	profile: ['profile'] as const,
};

export function useProfileQuery() {
	const queryClient = useQueryClient();

	return useQuery({
		queryKey: UserQueryKeys.profile,
		async queryFn() {
			try {
				return await getProfile();
			} catch (error) {
				if (error instanceof APIResponseError) {
					if (error.response.status === 401) {
						// User is not authenticated. Logout and return null
						console.log('User is not authenticated. Logging out.');
						clearAuth();
						queryClient.invalidateQueries({ queryKey: UserQueryKeys.profile });
						return null;
					}
				}
			}
		},
		staleTime: 1 * 60 * 60 * 1000, // 1 hour
		enabled: () => hasAuth(),
	});
}

export function useProfileName() {
	const { data: user } = useProfileQuery();
	return splitFullName(user?.name ?? '');
}

import { useQuery } from "@tanstack/react-query";
import { getAppInfo, getCountries, getCurrencies } from "../lib/api";
import { currencies } from "data/currencies";

export const AppQueryKeys = {
	appInfo: ['appInfo'] as const,
	currencies: ['currencies'] as const,
	countries: ['countries'] as const,
};

export const defaultAppInfo = {
	identity: {
		options: [],
	},
	supportedCountries: [],
	supportedCurrencies: [],
};

/** @deprecated Use AppDataContext instead */
export function useAppInfo() {
	return useQuery({
		queryKey: AppQueryKeys.appInfo,
		queryFn: getAppInfo,
		staleTime: 1 * 60 * 60 * 1000, // 1 hour
		initialData: defaultAppInfo,
		initialDataUpdatedAt: 0,
	});
}

export function useCurrenciesQuery() {
	return useQuery({
		queryKey: AppQueryKeys.currencies,
		queryFn: getCurrencies,
		staleTime: 12 * 60 * 60 * 1000, // 12 hours
		initialData: currencies,
		initialDataUpdatedAt: 0,
	});
}

export function useCountriesQuery() {
	return useQuery({
		queryKey: AppQueryKeys.countries,
		queryFn: getCountries,
		staleTime: 12 * 60 * 60 * 1000, // 12 hours
		initialData: [],
		initialDataUpdatedAt: 0,
	});
}

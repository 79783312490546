import React, { Fragment } from "react";
import { useState } from "react";
import sun from "../../assets/drawables/sun.svg";
import moon from "../../assets/drawables/moon.svg";
import "./style.scss";
import DropDownWrapper from "components/DropDownWrapper";
import { Avatar, Button, Radio } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LogOutIcon from "../../assets/drawables/logout.svg";
import avatar from "../../assets/drawables/avatar.png";
import bell from "../../assets/drawables/bell.svg";
import mark from "assets/drawables/markGreen.svg";
import Hamburger from "components/Hamburger";
import { Link } from "react-router-dom";
import cn from "classnames";
import upperFirst from "lodash/upperFirst";
import { useProfileName } from "pages/Authentication/queries";

type NavbarDashboardProps<TStepperList extends readonly string[]> = {
  header: string,
  showThemeToggle?: boolean
  stepper?: {
    active: TStepperList[number],
    list: TStepperList,
  }
}
export default function NavbarDashboard<TStepperList extends readonly string[]>({
  header,
  showThemeToggle = false,
  stepper,
}: NavbarDashboardProps<TStepperList>) {
  const [isNavOpen, setisNavOpen] = useState(false);
  const [firstName] = useProfileName();

  const showStepper = !!stepper;
  const stepperActiveIndex = stepper && stepper.list.indexOf(stepper.active);

  return (
    <div className="cover_all_nav">
      <Hamburger onClick={() => setisNavOpen(!isNavOpen)} />
      <div className={cn('pd_navbar_dashboard', { 'nav-opened': isNavOpen })}>
        <div className="page_title">
          <p>{header}</p>
        </div>

        {showStepper && (
          <div className="box_first">
            {stepper.list.map((item, index) => (
              <Fragment key={item}>
                <div className={cn('side', { active: stepper.active === item })}>
                  <p>{upperFirst(item)}</p>{' '}
                  {index < stepperActiveIndex! ? <img src={mark} alt="mark" /> : (
                    <Radio
                      value={item}
                      onChange={() => { }}
                      name="radio-buttons"
                      inputProps={{ "aria-label": item }}
                      checked={stepper.active === item}
                    />
                  )}{' '}
                </div>
                {(index !== stepper.list.length - 1) && <div className="separatorline"></div>}
              </Fragment>
            ))}
          </div>
        )}

        <div className="right">
          {showThemeToggle && (
            <div className="toggleSide">
              <img src={sun} alt="sun" />
              <div className="togDiv">
                <label className="switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
              </div>
              <img src={moon} alt="moon" />
            </div>
          )}
          <div className="cover_drop">
            <DropDownWrapper
              action={
                <Button
                  startIcon={
                    <Avatar
                      sx={{ width: 25, height: 25 }}
                      alt="Remy Sharp"
                      src={avatar}
                    />
                  }
                  endIcon={<ArrowDropDownIcon />}
                  sx={{ p: "0.5rem" }}
                  className="profile-action"
                >
                  <p className="name_action">{firstName}</p>
                </Button>
              }
              className="profile-drop-down"
            >
              <Link to='/logout'>
                <Button startIcon={<img src={LogOutIcon} alt="logout" />}>
                  Logout
                </Button>
              </Link>
            </DropDownWrapper>
          </div>
          <button className="notification">
            <img src={bell} alt="bell" />3
          </button>
        </div>
      </div>{" "}
    </div>
  );
};

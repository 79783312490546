import React, { useCallback, useEffect, useMemo } from "react";
import InputField from "components/InputFields";
import InputPhone from "components/InputFields/inputPhone";
import SelectField from "components/InputFields/SelectField";
import SubmitBtn from "components/SubmitBtn/SubmitBtn";
import { useState } from "react";
import { DeepPartial, FormProvider, useForm } from "react-hook-form";
import profile from "../../../assets/drawables/profile.svg";
import CustomFlagDropDown from "components/InputFields/customFlagDropDown";
import SupportBtn from "components/SupportBtn";
import { useNavigate } from "react-router-dom";
import BackBtn from "components/BackBtn";
import { InferType, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateProfile, uploadFile } from "../../../lib/api";
import { User } from "../../../lib/api/apiTypes";
import { splitFullName } from "../../../lib/utils";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import { AppInfo, FileUploadIntent } from "../../../lib/api/requestTypes";
import { useProfileQuery, UserQueryKeys } from "../queries";
import { useAppInfo } from "../../../default/queries";
import { ComponentLoader } from "../../../components/ComponentLoader";
import { Alert } from "@mui/material";

const personalInfoSchema = object({
	firstName: string().required('First name is required'),
	middleName: string(),
	lastName: string().required('Last name is required'),
	gender: string().required().oneOf(['male', 'female']),
	dob: string().required(),
	email: string().required(),
	phone: string().required(),
	address: object({
		unit: string(),
		buildingNo: string().nullable(),
		street: string().required(),
		postalCode: string().required(),
		city: string().required(),
		state: string().required(),
		country: string().required(),
	}),
});

type PersonalInfoSchema = InferType<typeof personalInfoSchema>;

function mapUserValues(user: User): DeepPartial<PersonalInfoSchema> {
	const [firstName, middleName, lastName] = splitFullName(user.name ?? '');

	return {
		firstName,
		middleName,
		lastName,
		email: user.email,
		phone: user.phone ?? '',
		gender: user.gender ?? '',
		dob: user.dob ?? '',
		address: pickBy(user.address),
	};
}

type PersonalInfoFormProps = {
	appInfo: AppInfo,
	user: User,
}
const PersonalInfoForm = ({ appInfo, user }: PersonalInfoFormProps) => {
	const navigate = useNavigate();
	const [upload, setUpload] = useState<File>();
	const [errorDismissed, setErrorDismissed] = useState(false);
	const queryClient = useQueryClient();

	const updateProfileMutation = useMutation({ mutationFn: updateProfile });
	const uploadFileMutation = useMutation({ mutationFn: uploadFile });

	const methods = useForm({ resolver: yupResolver(personalInfoSchema) });

	useEffect(() => {
		methods.reset(mapUserValues(user))
	}, [user, methods]);

	/** @todo Remove form error logging */
	useEffect(() => {
		if (Object.keys(methods.formState.errors).length) {
			console.error(methods.formState.errors);
		}
	}, [methods.formState.errors]);


	const onSubmit = useCallback(async (data: PersonalInfoSchema) => {
		try {
			const mutationData = {
				name: `${data.firstName} ${data.middleName} ${data.lastName}`.replace(/\s+/g, ' '),
				gender: data.gender,
				dob: data.dob,
				address: omit(data.address, 'country'),
			};
			await updateProfileMutation.mutateAsync(mutationData);

			if (upload) {
				const form = new FormData();
				form.set('intent', FileUploadIntent.ADDRESS_PROOF);
				form.set('file', upload);
				await uploadFileMutation.mutateAsync(form);
			}

			queryClient.invalidateQueries({ queryKey: UserQueryKeys.profile });
			setUpload(undefined);
			navigate("/complete/identity");
		} catch (e) {
			console.error(e);
		} finally {
			setErrorDismissed(false);
		}
	}, [navigate, queryClient, updateProfileMutation, upload, uploadFileMutation]);

	// Get residency prrof file name
	let addressProofFilename = '';
	if (upload) addressProofFilename = upload.name;
	else if (user.address.proof) addressProofFilename = user.address.proof.name;

	// Get state select options
	const stateOptions = useMemo(() => {
		const country = appInfo.supportedCountries.find(country => country.code === user.country);
		if (!country) return [];
		return country.states.map(state => ({ label: state.name, value: state.code }));
	}, [appInfo.supportedCountries, user]);

	const isLoading = updateProfileMutation.isPending || uploadFileMutation.isPending;
	const hasError = updateProfileMutation.isError || uploadFileMutation.isError;
	const error = updateProfileMutation.error || uploadFileMutation.error;

	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<div className="formHead">
					<img src={profile} alt="profile" />
					<p>Tell us about yourself</p>
				</div>

				{hasError && !errorDismissed && error instanceof Error && (
					<Alert severity="warning" onClose={() => setErrorDismissed(true)}>{error.message}</Alert>
				)}

				<InputField type="text" name="firstName" id="firstName" label="First Name" required />
				<InputField type="text" name="middleName" id="middleName" label="Middle Name" />
				<InputField type="text" name="lastName" id="lastName" label="Last Name" required />
				<InputField type="email" name="email" id="email" label="Email Address" disabled />
				<InputPhone countries={appInfo.supportedCountries} label="Enter mobile Number" name="phone" disabled />
				<div className="form-group-wrap">
					<InputField type="date" name="dob" id="dob" label="Date of Birth" required />
					<SelectField
						label="Gender"
						name="gender"
						selectOption={[
							{ label: "Male", value: "male" },
							{ label: "Female", value: "female" },
						]}
						required
					/>
				</div>
				<div className="form-group-wrap">
					<div className="form-group">
						<CustomFlagDropDown countries={appInfo.supportedCountries} name="address.country" label="Country" disabled />
					</div>

					<SelectField label="State" name="address.state" selectOption={stateOptions} required />
				</div>

				<div className="residential_info">
					<p className="header_text">Residential Address</p>
					<div className="form-group-wrap">
						<InputField type="text" name="address.unit" placeholder="" id="unit" label="Flat/Unit No" />
						<InputField type="text" name="address.buildingNo" placeholder="" id="building_name" label="Building Name/No" />
					</div>
				</div>

				<InputField type="text" name="address.street" placeholder="" id="street" label="Street" required />

				<div className="form-group-wrap">
					<InputField type="text" name="address.postalCode" placeholder="" id="postalCode" label="Postal Code" required />
					<InputField type="text" name="address.city" placeholder="" id="city" label="City/Town" required />
				</div>

				<div className="file_upload">
					<p className="text_head">Upload proof of residency</p>
					<p className="sub_text">
						Proof accepted includes, bank or credit card statement showing
						addressing, utility bills showing address
					</p>

					<div className="cover_file">
						<label htmlFor="residency">
							<p className="upload">{addressProofFilename}</p>
						</label>
						<input
							onChange={(e) => {
								if (e.target.files?.length) {
									setUpload(e.target.files[0]);
								}
							}}
							hidden
							type="file"
							name="residency"
							id="residency"
						/>
					</div>
				</div>

				<SubmitBtn disabled={isLoading} isLoading={isLoading} btnText='Continue' />
			</form>
		</FormProvider>
	);
};

const PersonalInfo = () => {
	const appInfoQuery = useAppInfo();
	const userQuery = useProfileQuery();

	const componentPropsFn = (appInfo: AppInfo, user: User) => ({ appInfo, user });

	return (
		<div className="pd_complete_registration">
			<BackBtn />
			<SupportBtn />
			<div className="center_container">
				<ComponentLoader
					component={PersonalInfoForm}
					queries={[appInfoQuery, userQuery]}
					componentPropsFn={componentPropsFn}
				/>
			</div>
		</div>
	);
}

export default PersonalInfo;

import ConvertCurrencyDropDown from "components/InputFields/convertCurrencyDropDown";
import { useState } from "react";
import ques from "assets/drawables/ques.svg";
import nig from "assets/drawables/nigeria.svg";
import eng from "assets/drawables/england.svg";
import yen from "assets/drawables/yen.svg";
import cn from "classnames";
import { useCurrenciesQuery } from "default/queries";


const flagList = [
	{ flag: nig, code: 'NGN' },
	{ flag: eng, code: 'USD' },
	{ flag: yen, code: 'JPY' },
];

export interface EarningsProps {
	tab: 'overview' | 'currency';
}

export function Earnings({ tab }: EarningsProps) {
	const { data: currencies } = useCurrenciesQuery();

	const [selectedCurrency, setSelectedCurrency] = useState(currencies[0].code);
	const [totalEarningsCurrency, setTotalEarningsCurrency] = useState(flagList[0].code);

	return (
		<div className="top_left_ref">
			<div className={cn('top_side', { pad: tab === 'currency' })}>
				{tab === "overview" ? (
					<div className="ref_overview">
						<p className="see">See your total earnings in </p>
						<ConvertCurrencyDropDown
							currencies={currencies}
							value={selectedCurrency}
							onChange={setSelectedCurrency}
						/>
						<img className="ques" src={ques} alt="ques" />
					</div>
				) : (
					<div className="select_flag">
						{flagList.map((item) => {
							return (
								<div
									className={cn('each_ref_flag', { active_code: item.code === totalEarningsCurrency })}
									onClick={() => setTotalEarningsCurrency(item.code)}
									key={item.code}
								>
									<img src={item.flag} className="flag" alt="" />
									<p className="code">{item.code}</p>
								</div>
							);
						})}
					</div>
				)}
			</div>
			<div className="bottom_side">
				<div className="each_total">
					<p className="earn">Total earned</p>
					<p className="amount">$0.00</p>
				</div>
				<div className="each_total">
					<p className="earn">No. of people referred</p>
					<p className="amount">0</p>
				</div>
			</div>
		</div>
	);
}
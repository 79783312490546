import { Alert, Box, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import SelectCurrencyIce from "../../../../components/InputFields/SelectCurrencyIce";
import SubmitBtn from "../../../../components/SubmitBtn/SubmitBtn";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createWallet } from "../../../../lib/api";
import { useNavigate } from "react-router-dom";
import { useProfileQuery, UserQueryKeys } from "../../../Authentication/queries";
import { useCurrenciesQuery } from "default/queries";

export default function CreateAccountBox() {
	const { data: user } = useProfileQuery();
	const { data: currencies } = useCurrenciesQuery();
	const navigate = useNavigate();

	const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null);

	// Get currencies user doesn't have wallet for
	const currenciesWithoutWallet = useMemo(() => {
		const userCurrencySet = new Set(user?.wallets.map(wallet => wallet.currency));
		return currencies.filter((item) => !userCurrencySet.has(item.code));
	}, [currencies, user?.wallets]);

	const queryClient = useQueryClient();
	const createWalletMutation = useMutation({
		mutationFn: createWallet,
		onSuccess(data) {
			const wallet = data.data;
			queryClient.invalidateQueries({ queryKey: UserQueryKeys.profile });
			navigate(`/dashboard/bank/${wallet.id}`);
		},
	});

	const error = createWalletMutation?.error;

	return (
		<Box height={400} margin="auto" maxWidth={350}>
			<Typography marginBottom={3} fontFamily="myFirstFont" fontWeight={600} color="#0d458a" fontSize={17}>
				Select currency
			</Typography>
			{error instanceof Error && (
				<Box marginBottom={1}>
					<Alert severity="error" onClose={() => createWalletMutation?.reset()}>
						{error.message}
					</Alert>
				</Box>
			)}
			<SelectCurrencyIce
				value={selectedCurrency}
				onChange={setSelectedCurrency}
				currencies={currenciesWithoutWallet}
			/>
			<SubmitBtn
				disabled={!selectedCurrency}
				className="green"
				btnText="Proceed"
				isLoading={createWalletMutation.isPending}
				onClick={() => selectedCurrency && createWalletMutation.mutate(selectedCurrency)}
			/>
		</Box>
	);
}
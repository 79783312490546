import { useQuery } from "@tanstack/react-query";
import { getMarketInfo, getSecondaryMarketInfo } from "lib/api";
import { MarketSecurity, SecondarySecurity } from "lib/api/apiTypes";

export type Security = MarketSecurity & SecondarySecurity;

export const MarketQueryKeys = {
	securities: ['securities'] as const,
};

export function useSecuritiesQuery() {
	return useQuery({
		queryKey: MarketQueryKeys.securities,
		async queryFn(): Promise<Security[]> {
			const [primary, secondary] = await Promise.all([
				getMarketInfo(),
				getSecondaryMarketInfo(),
			]);

			// Combine primary and secondary markets
			const mappedSecondary: Record<string, SecondarySecurity> = {};
			secondary.forEach((security) => {
				mappedSecondary[security.currency] = security;
			});

			const combined = primary.map((security) => ({
				...security,
				...mappedSecondary[security.currency],
			}));

			return combined;
		},
		staleTime: 1 * 60 * 1000, // 5 mins
		initialData: [],
		initialDataUpdatedAt: 0,
	});
}

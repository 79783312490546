import type { generateMarketAssets } from "lib/random";
import { Security } from "./queries";

export const marketTypes = {
	primary: {
		name: 'Primary Markets',
	},
	secondary: {
		name: 'Secondary Markets',
	},
};
export type MarketType = keyof typeof marketTypes;
export type Asset = ReturnType<typeof generateMarketAssets>[0];

export const percentFormatter = new Intl.NumberFormat('en-US', {
	style: 'percent',
	maximumFractionDigits: 2,
});

export const numberFormatter = new Intl.NumberFormat('en-US', {
	style: 'decimal',
	maximumFractionDigits: 2,
});

export const trendUpData = [0, 10, 5, 22, 3.6, 11];
export const trendDownData = [11, 3.6, 22, 5, 10, 0];

export const filters = ['watchlist', 'alphabetical', 'ratings', 'yield'] as const;
export const transformers = {
	'alphabetical': {
		name: 'Alphabetical (A-Z)',
		transformer(assets: Security[]) {
			return assets.slice().sort((a, b) => a.currency.localeCompare(b.currency));
		},
		next: 'alphabetical:z-a',
	},
	'alphabetical:z-a': {
		name: 'Alphabetical (Z-A)',
		transformer(assets: Security[]) {
			return assets.slice().sort((a, b) => b.currency.localeCompare(a.currency));
		},
		next: null,
	},
	'ratings': {
		name: 'Ratings (Low - High)',
		transformer(assets: Security[]) {
			return assets.slice().sort((a, b) => a.currencyRecord.rating.localeCompare(b.currencyRecord.rating));
		},
		next: 'ratings:9-0',
	},
	'ratings:9-0': {
		name: 'Ratings (High - Low)',
		transformer(assets: Security[]) {
			return assets.slice().sort((a, b) => b.currencyRecord.rating.localeCompare(a.currencyRecord.rating));
		},
		next: null,
	},
	'watchlist': {
		name: 'Watchlist',
		transformer(assets: Security[], watchlist: string[]) {
			return assets.filter((asset) => watchlist.includes(asset.currency));
		},
		next: null,
	},
	'yield': {
		name: 'Yield (Low - High)',
		transformer(assets: Security[]) {
			return assets.slice().sort((a, b) => a.yieldPa - b.yieldPa);
		},
		next: 'yield:9-0',
	},
	'yield:9-0': {
		name: 'Yield (High - Low)',
		transformer(assets: Security[]) {
			return assets.slice().sort((a, b) => b.yieldPa - a.yieldPa);
		},
		next: null,
	},
} as const;
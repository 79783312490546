import SubmitBtn from "components/SubmitBtn/SubmitBtn";

import SendMoneyHeader from "../../widget/SendMoneyHeader";
import "./style.scss";

import arrow from "assets/drawables/arrow.svg";
import house from "assets/drawables/house.svg";


import { useSendMoneyContext } from "../../SendMoneyProvider";
import { TransferRecipient } from "../../../../../lib/api/apiTypes";
import { useCurrenciesQuery } from "default/queries";


function Recipient({ recipient }: { recipient: TransferRecipient }) {
  const types = {
    'external-bank-account': 'Bank Account',
    user: 'Wallet',
  }

  return (
    <div className="recipient_side">
      <p className="name">Recipient</p>
      {recipient.type === 'external-bank-account' && (
        <>
          <p className="bank bold">{recipient.bankName} {recipient.currency}</p>
          <p>{recipient.accountNumber}</p>
        </>
      )}
      <div className="name_box">
        <p className="acc_name">{recipient?.name}</p>
        <div className="type_side">
          <p className="bold">{types[recipient.type]}</p>
          <img src={house} alt="house" />
        </div>
      </div>
    </div>
  );
}

export const SummaryStep = () => {
  const { data: currencies } = useCurrenciesQuery();
  const { createTransferMutation, transfer } = useSendMoneyContext();

  const {
    recipient,
    fromCurrency: fromCurrencyCode,
    toCurrency: toCurrencyCode,
    sendingAmount,
    receivingAmount,
  } = transfer;

  const fromCurrency = currencies.find(item => item.code === fromCurrencyCode)!;
  const toCurrency = currencies.find(item => item.code === toCurrencyCode)!;

  const btnOnClick = () => createTransferMutation!.mutate();

  return (
    <div className="pd_summary">
      <SendMoneyHeader active='summary' />
      <div className="main_aspect">
        <p className="first_text_send bold">Summary</p>
        <p className="sub">
          Please review the details below then click continue to proceed
        </p>
        <div className="summary_side">
          <div className="convert">
          <img src={fromCurrency.flag} width={26} alt={fromCurrency.name} />
            <img src={arrow} alt="arrow" className="arrow" />
            <img src={toCurrency.flag} width={26} alt={toCurrency.name} />
          </div>

          <div className="desc_box">
            <div className="each_desc">
              <p className="faint">You are sending</p>
              <p className="bold">{fromCurrency.symbol}{sendingAmount}</p>
            </div>
            <div className="each_desc">
              <p className="faint">Recipient will recieve </p>
              <p className="bold">{toCurrency.symbol}{receivingAmount}</p>
            </div>
            <div className="each_desc">
              <p className="faint">Exchange rate</p>
              <p className="bold">1 {fromCurrency.code} = 1 {toCurrency.code}</p>
            </div>
            <div className="each_desc">
              <p className="faint">Processing fee</p>
              <p className="bold">{toCurrency.symbol}0.00</p>
            </div>
          </div>

          <Recipient recipient={recipient!} />

          <p className="total_money bold">Total: {fromCurrency.symbol}{sendingAmount}</p>
          <SubmitBtn
            isLoading={createTransferMutation?.isPending}
            onClick={btnOnClick}
            className="green"
            btnText='Proceed'
            />
          <button className="cancel">Cancel</button>
        </div>
      </div>
    </div>
  );
};

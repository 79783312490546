import { useMemo, useState } from "react";
import "./style.scss";
import EachAccountCard from "../EachAccountCard";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import ConvertCurrencyDropDown from "components/InputFields/convertCurrencyDropDown";
import { formatAmount, mapCurrencies } from "../../../../lib/utils";
import Modal from "../../../../components/ModalPopUp";
import CreateAccountBox from "../CreateAccount/CreateAccountBox";
import { useCurrenciesQuery } from "default/queries";
import { useProfileQuery } from "pages/Authentication/queries";

const AccountIce = () => {
  const { data: user } = useProfileQuery();
  const { data: currencies } = useCurrenciesQuery();
  const wallets = user!.wallets;

  const currencyMap = useMemo(() => mapCurrencies(currencies), [currencies]);
  const [currency, setCurrency] = useState(currencies[0].code);

  const [walletCreateOpen, setWalletCreateOpen] = useState(false);

  const canCreateWallet = wallets.length < currencies.length;

  return (
    <div className="pd_account_ice">
      <div className="total_div">
        <div className="top_side">
          <p className="balance">Total Balance</p>
          <ConvertCurrencyDropDown
            value={currency}
            onChange={setCurrency}
            currencies={currencies}
          />
        </div>
        <p className="totalamount">$13,345.20</p>
      </div>
      {!wallets.length && (
        <Link to="/bank/create_account" className="create_box">
          + Create Account
        </Link>
      )}
      <Grid container spacing={4} mt={1}>
        {wallets.map(wallet => {
          return (
            <Grid key={wallet.id} item xs={12} sm={6} md={4} lg={3}>
              <Link to={`/dashboard/bank/${wallet.id}`} style={{ textDecoration: 'none' }}>
                <EachAccountCard
                  id={wallet.id}
                  amount={formatAmount(wallet.balance, wallet.currency)}
                  flag={currencyMap[wallet.currency].flag}
                  currency={currencyMap[wallet.currency].name}
                />
              </Link>
            </Grid>
          );
        })}
        {canCreateWallet && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <div onClick={() => setWalletCreateOpen(true)} className="create_box inList">
              + Create Account
            </div>
          </Grid>
        )}
      </Grid>

      <Modal openModal={walletCreateOpen} closeModal={() => setWalletCreateOpen(false)}>
        <CreateAccountBox />
      </Modal>
    </div>
  );
};

export default AccountIce;
